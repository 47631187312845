//新装
export const newAddColumn = [
  {
    display: true,
    fieldName: "itemId",
    fieldLabel: "工单ID",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "itemStatus",
    fieldLabel: "任务项状态",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "warnStatusName",
    fieldLabel: "超期预警",
    width: 100,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "sceneName",
    fieldLabel: "场景名称",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "sceneType",
    fieldLabel: "场景类型",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "isNeedVisit",
    fieldLabel: "是否上门",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "scheduleId",
    fieldLabel: "调度任务ID",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "scheduleInfoId",
    display: true,
    fieldLabel: "调度信息ID",
    width: 140,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "engineerName",
    fieldLabel: "工程师",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "engineerPhone",
    fieldLabel: "工程师电话",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "arrangedate",
    fieldLabel: "约定时间",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "arrivaltime",
    fieldLabel: "签到时间",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "fixendtime",
    fieldLabel: "完成时间",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "contactName",
    fieldLabel: "安装联系人",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "contactPhone",
    fieldLabel: "联系电话",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "address",
    fieldLabel: "联系地址",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "installReq",
    fieldLabel: "安装要求",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },

  {
    display: true,
    fieldName: "assignor",
    fieldLabel: "指派人",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "assignTime",
    fieldLabel: "指派时间",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  }
];
//加装
export const addColumn = [
  {
    display: true,
    fieldName: "itemId",
    fieldLabel: "工单ID",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "itemStatus",
    fieldLabel: "任务项状态",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "warnStatusName",
    fieldLabel: "超期预警",
    width: 100,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "sceneName",
    fieldLabel: "场景名称",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "sceneType",
    fieldLabel: "场景类型",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "isNeedVisit",
    fieldLabel: "是否上门",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "scheduleId",
    fieldLabel: "调度任务ID",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "scheduleInfoId",
    display: true,
    fieldLabel: "调度信息ID",
    width: 140,
    disabled: false,
    fixed: false,
    align: "center"
  },

  {
    display: true,
    fieldName: "engineerName",
    fieldLabel: "工程师",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "engineerPhone",
    fieldLabel: "工程师电话",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "arrangedate",
    fieldLabel: "约定时间",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "arrivaltime",
    fieldLabel: "签到时间",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "fixendtime",
    fieldLabel: "完成时间",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "contactName",
    fieldLabel: "安装联系人",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "contactPhone",
    fieldLabel: "联系电话",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "address",
    fieldLabel: "联系地址",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },

  {
    display: true,
    fieldName: "assignor",
    fieldLabel: "指派人",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "assignTime",
    fieldLabel: "指派时间",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  }
];
//新装、加装折叠table
export const expandedColumn = [
  {
    display: true,
    fieldName: "productName",
    fieldLabel: "产品名称",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "productNo",
    fieldLabel: "产品型号",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "thirdClassName",
    fieldLabel: "商品名称",
    width: 180,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "firstClassName",
    fieldLabel: "商品分类",
    width: 180,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "secondClassName",
    fieldLabel: "商品开票名称",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "equipType",
    fieldLabel: "设备类型",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "equipCombo",
    fieldLabel: "设备组合",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "installStatus",
    fieldLabel: "安装状态",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "installDateStr",
    fieldLabel: "安装时间",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "equipCode",
    fieldLabel: "设备编号",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "simNo",
    fieldLabel: "SIM卡号",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "centerId",
    fieldLabel: "中心识别码",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  }
  // {
  //   display: true,
  //   fieldName: "a",
  //   fieldLabel: "",
  //   width: 120,
  //   disabled: false,
  //   fixed: false,
  //   align: "center"
  // }
];
//维修
export const repairColumn = [
  {
    display: true,
    fieldName: "itemId",
    fieldLabel: "工单ID",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "itemStatus",
    fieldLabel: "任务项状态",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "warnStatusName",
    fieldLabel: "超期预警",
    width: 100,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "sceneName",
    fieldLabel: "场景名称",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "sceneType",
    fieldLabel: "场景类型",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "isNeedVisit",
    fieldLabel: "是否上门",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "scheduleId",
    fieldLabel: "调度任务ID",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "scheduleInfoId",
    display: true,
    fieldLabel: "调度信息ID",
    width: 140,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "engineerName",
    fieldLabel: "工程师",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "engineerPhone",
    fieldLabel: "工程师电话",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "arrangedate",
    fieldLabel: "约定时间",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "arrivaltime",
    fieldLabel: "签到时间",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "fixendtime",
    fieldLabel: "完成时间",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "contactName",
    fieldLabel: "安装联系人",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "contactPhone",
    fieldLabel: "联系电话",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "address",
    fieldLabel: "联系地址",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },

  {
    display: true,
    fieldName: "questionClass",
    fieldLabel: "问题类型",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "quesName",
    fieldLabel: "问题描述",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "quesReason",
    fieldLabel: "诊断问题",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "solution",
    fieldLabel: "处理方案",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  // {
  //   display: true,
  //   fieldName: "result",
  //   fieldLabel: "维修结果",
  //   width: 160,
  //   disabled: false,
  //   fixed: false,
  //   align: "center"
  // },

  {
    display: true,
    fieldName: "assignor",
    fieldLabel: "指派人",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "assignTimeStr",
    fieldLabel: "指派时间",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  }
];
//巡检地点
export const checkColumn = [
  {
    display: true,
    fieldName: "address",
    fieldLabel: "巡检点地址",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "contactName",
    fieldLabel: "联系人",
    width: 140,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "contactPhone",
    fieldLabel: "联系人电话",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "engineerLeader",
    fieldLabel: "工程组长",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "engineer",
    fieldLabel: "驻点工程师",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  }
];
// 巡检任务项
export const inspectionColumn = [
  {
    display: true,
    fieldName: "itemId",
    fieldLabel: "工单ID",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "itemStatus",
    fieldLabel: "任务项状态",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "warnStatusName",
    fieldLabel: "超期预警",
    width: 100,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "sceneName",
    fieldLabel: "场景名称",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "sceneType",
    fieldLabel: "场景类型",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "contactName",
    fieldLabel: "安装联系人",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "contactPhone",
    fieldLabel: "联系电话",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "address",
    fieldLabel: "联系地址",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "equipName",
    fieldLabel: "商品名称",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "equipType",
    fieldLabel: "商品分类",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "equipCode",
    fieldLabel: "设备编号",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "commids",
    fieldLabel: "中心识别码",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "questionClass",
    fieldLabel: "问题类型",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "quesName",
    fieldLabel: "问题描述",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "quesReason",
    fieldLabel: "诊断问题",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "programme",
    fieldLabel: "处理方案",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "scheduleId",
    fieldLabel: "调度任务ID",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "scheduleInfoId",
    display: true,
    fieldLabel: "调度信息ID",
    width: 140,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "engineerName",
    fieldLabel: "工程师",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "engineerPhone",
    fieldLabel: "工程师电话",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "assignor",
    fieldLabel: "指派人",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "assignTimeStr",
    fieldLabel: "指派时间",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  }
];
//重装
export const reinstallColumn = [
  {
    display: true,
    fieldName: "itemId",
    fieldLabel: "工单ID",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "itemStatus",
    fieldLabel: "任务项状态",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "warnStatusName",
    fieldLabel: "超期预警",
    width: 100,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "sceneName",
    fieldLabel: "场景名称",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "sceneType",
    fieldLabel: "场景类型",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "isNeedVisit",
    fieldLabel: "是否上门",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "scheduleId",
    fieldLabel: "调度任务ID",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "scheduleInfoId",
    display: true,
    fieldLabel: "调度信息ID",
    width: 140,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "engineerName",
    fieldLabel: "工程师",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "engineerPhone",
    fieldLabel: "工程师电话",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "arrangedate",
    fieldLabel: "约定时间",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "arrivaltime",
    fieldLabel: "签到时间",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "fixendtime",
    fieldLabel: "完成时间",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "contactName",
    fieldLabel: "安装联系人",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "contactPhone",
    fieldLabel: "联系电话",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "address",
    fieldLabel: "联系地址",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "equCombination",
    fieldLabel: "安装组合",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "equipName",
    fieldLabel: "商品名称",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },

  {
    display: true,
    fieldName: "equipType",
    fieldLabel: "商品分类",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "equipCode",
    fieldLabel: "设备编号",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "reloadSceneName",
    fieldLabel: "重装场景名称",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "reloadSceneType",
    fieldLabel: "重装场景类型",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },

  {
    display: true,
    fieldName: "assignor",
    fieldLabel: "指派人",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "assignTime",
    fieldLabel: "指派时间",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  }
];
//拆卸
export const removeColumn = [
  {
    display: true,
    fieldName: "itemId",
    fieldLabel: "工单ID",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "itemStatus",
    fieldLabel: "任务项状态",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "warnStatusName",
    fieldLabel: "超期预警",
    width: 100,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "sceneName",
    fieldLabel: "场景名称",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "sceneType",
    fieldLabel: "场景类型",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "isNeedVisit",
    fieldLabel: "是否上门",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "scheduleId",
    fieldLabel: "调度任务ID",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "scheduleInfoId",
    display: true,
    fieldLabel: "调度信息ID",
    width: 140,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "engineerName",
    fieldLabel: "工程师",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "engineerPhone",
    fieldLabel: "工程师电话",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "arrangedate",
    fieldLabel: "约定时间",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "arrivaltime",
    fieldLabel: "签到时间",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "fixendtime",
    fieldLabel: "完成时间",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "contactName",
    fieldLabel: "安装联系人",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "contactPhone",
    fieldLabel: "联系电话",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "address",
    fieldLabel: "联系地址",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "equCombination",
    fieldLabel: "安装组合",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "equipName",
    fieldLabel: "拆卸商品名称",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },

  {
    display: true,
    fieldName: "equipType",
    fieldLabel: "商品分类",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "equipCode",
    fieldLabel: "设备编号",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },

  {
    display: true,
    fieldName: "assignor",
    fieldLabel: "指派人",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "assignTimeStr",
    fieldLabel: "指派时间",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  }
];
//调度任务表头
export const schedulTasksColumn = [
  {
    fieldName: "tag",
    display: true,
    fieldLabel: "标签",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center",
    class: ""
  },
  {
    fieldName: "scheduleTaskId",
    display: true,
    fieldLabel: "调度任务ID",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center",
    class: ""
  },
  {
    fieldName: "scheduleTaskStatus",
    display: true,
    fieldLabel: "调度任务状态",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "arrangeDateStr",
    display: true,
    fieldLabel: "约定时间",
    width: 180,
    disabled: false,
    fixed: false,
    align: "center"
  },

  {
    fieldName: "finishTimeStr",
    display: true,
    fieldLabel: "完成时间",
    width: 180,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "address",
    display: true,
    fieldLabel: "安装地址",
    width: 180,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "contactName",
    display: true,
    fieldLabel: "安装联系人",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center",
    mode: "color",
    color: "#79a3f2"
  },
  {
    fieldName: "contactPhone",
    display: true,
    fieldLabel: "联系人电话",
    width: 120,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "areaName",
    display: true,
    fieldLabel: "所属片区",
    width: 120,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "assignEngineer",
    display: true,
    fieldLabel: "片区工程组长",
    width: 140,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "installCount",
    display: true,
    fieldLabel: "任务项总数",
    width: 140,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "noAssignCount",
    display: true,
    fieldLabel: "待指派",
    width: 120,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "noConfirm",
    display: true,
    fieldLabel: "待确认",
    width: 120,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "waitWorkingCount",
    display: true,
    fieldLabel: "待作业",
    width: 120,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "workingCount",
    display: true,
    fieldLabel: "作业中",
    width: 120,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "finishCount",
    display: true,
    fieldLabel: "已完成",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "abnormalFinishCount",
    display: true,
    fieldLabel: "异常完成",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "createBy",
    display: true,
    fieldLabel: "创建人",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "createdTimeStr",
    display: true,
    fieldLabel: "创建时间",
    width: 180,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "remark",
    display: true,
    fieldLabel: "备注",
    width: 180,
    disabled: false,
    fixed: false,
    align: "center"
  }
];
//调度信息表头
export const schedulInfoColumn = [
  {
    fieldName: "tag",
    display: true,
    fieldLabel: "标签",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center",
    class: ""
  },

  {
    fieldName: "isNeedVisit",
    display: true,
    fieldLabel: "是否需要上门",
    width: 120,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "scheduleInfoId",
    display: true,
    fieldLabel: "调度信息ID",
    width: 140,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "scheduleInfoStatus",
    display: true,
    fieldLabel: "调度信息状态",
    width: 140,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "scheduleTaskId",
    display: true,
    fieldLabel: "所属调度任务ID",
    width: 140,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "engineerName",
    display: true,
    fieldLabel: "工程师",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "engineerPhone",
    display: true,
    fieldLabel: "工程师电话",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "taskArrangeTimeStr",
    display: true,
    fieldLabel: "预约上门时间",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "changeDate",
    display: true,
    fieldLabel: "工程师计划上门时间",
    width: 180,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "signTimeStr",
    display: true,
    fieldLabel: "签到时间",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center",
    mode: "color",
    color: "#79a3f2"
  },
  {
    fieldName: "finishTimeStr",
    display: true,
    fieldLabel: "完成时间",
    width: 180,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "installCount",
    display: true,
    fieldLabel: "任务项总数",
    width: 140,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "waitWorking",
    display: true,
    fieldLabel: "待作业",
    width: 120,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "workingCount",
    display: true,
    fieldLabel: "作业中",
    width: 120,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "finishCount",
    display: true,
    fieldLabel: "已完成",
    width: 120,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "abnormalFinishCount",
    display: true,
    fieldLabel: "异常完成",
    width: 120,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "createBy",
    display: true,
    fieldLabel: "创建人",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "createdTimeStr",
    display: true,
    fieldLabel: "创建时间",
    width: 180,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "remark",
    display: true,
    fieldLabel: "备注",
    width: 180,
    disabled: false,
    fixed: false,
    align: "center"
  }
];
//费用明细表头
export const feeColumn = [
  {
    fieldName: "sceneName",
    display: true,
    fieldLabel: "场景名称",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "equipFee",
    display: true,
    fieldLabel: "设备费（元）",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "serviceFee",
    display: true,
    fieldLabel: "服务费（元）",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "reducedPrice",
    display: true,
    fieldLabel: "优惠",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "currencyName",
    display: true,
    fieldLabel: "币种",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "status",
    display: true,
    fieldLabel: "合计",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center",
    mode: "color",
    color: "#79a3f2"
  }
];
//通话记录表头
export const callRecordsColumn = [
  {
    fieldName: "callerName",
    display: true,
    fieldLabel: "主叫名称",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center",
    class: ""
  },
  {
    fieldName: "callerNum",
    display: true,
    fieldLabel: "主叫号码",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center",
    class: ""
  },
  {
    fieldName: "calleeName",
    display: true,
    fieldLabel: "被叫名称",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center",
    class: ""
  },
  {
    fieldName: "calleeNum",
    display: true,
    fieldLabel: "被叫号码",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center",
    class: ""
  },
  {
    fieldName: "startTime",
    display: true,
    fieldLabel: "开始时间",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center",
    class: ""
  },
  {
    fieldName: "endTime",
    display: true,
    fieldLabel: "结束时间",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center",
    class: ""
  },
  {
    fieldName: "duration",
    display: true,
    fieldLabel: "通话时长(S)",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center",
    class: ""
  },
  {
    fieldName: "createdTime",
    display: true,
    fieldLabel: "创建时间",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center",
    class: ""
  },
  {
    fieldName: "callId",
    display: true,
    fieldLabel: "通话唯一标识",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center",
    class: ""
  },
  {
    fieldName: "remark",
    display: true,
    fieldLabel: "备注",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center",
    class: ""
  }
];
